// Node Modules
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { RichText } from 'prismic-reactjs';

// Components
import Button from '@/components/global-components/Button';

// Themes
import theme from '@/themes/index';

const defaultProps = {
  showCTAButtons: false,
  city: '',
  locationName: '',
  description: '',
  primaryCtaText: '',
  primaryCtaUrl: '',
  secondaryCtaText: '',
  secondaryCtaUrl: '',
  src: '',
};

const propTypes = {
  showCTAButtons: PropTypes.bool.isRequired,
  city: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  description: PropTypes.array.isRequired,
  primaryCtaText: PropTypes.string.isRequired,
  primaryCtaUrl: PropTypes.string.isRequired,
  secondaryCtaText: PropTypes.string.isRequired,
  secondaryCtaUrl: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

const Location = ({
  city,
  locationName,
  description,
  callToActions = [],
  images,
  customAttr,
  style,
  imageStyle,
  locationDetailsStyle,
  primaryCtaStyle,
  secondaryCtaStyle,
}) => {
  const actions = callToActions.map(action => action?.callToAction ?? {});

  return (
    <>
      <div className='container' style={style}>
        <img
          className='location-picture'
          src={images[0].image.url}
          style={imageStyle}
        />
        <div className='location-details' style={locationDetailsStyle}>
          <div className='details-container'>
            <h3 className='city'>{city}</h3>
            <h3 className='location-name'>{locationName}</h3>
            <RichText render={description} />
            {actions.length && (
              <div className='cta-container'>
                {actions.map(action => (
                  <Button
                    customAttr={action.url.search('embed') > 0 ? customAttr : null}
                    key={nanoid()}
                    className={`watch-video ${action.style.toLowerCase()}`}
                    text={action.title}
                    href={`${action.url}`}
                    tabIndex={0}
                    styles={
                      action.style.toLowerCase() === 'primary'
                        ? primaryCtaStyle
                        : secondaryCtaStyle
                    }
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        div.container {
          text-align: center;
        }

        div.location-details {
          padding-top: 2.5rem;
        }

        h3 {
          margin: 0;
          line-height: 2.5rem;
        }

        h3.city {
          font-family: ${theme.fontFamilies.montserratSemiBold};
        }

        h3.location-name {
          font-family: ${theme.fontFamilies.montserratLight};
        }

        div.cta-container {
          display: flex;
          justify-content: center;
          gap: 0.5rem;
        }

        img.location-picture {
          width: 100%;
          height: 32rem;
          object-fit: cover;
          object-position: 35% 10%;
        }

        @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
          h3 {
            line-height: 56px;
          }

          div.cta-container {
            width: 100%;
            margin: 0 auto;
            justify-content: center;
          }
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.cta-container {
            margin: 0;
            justify-content: left;
          }

          div.container {
            display: flex;
            flex-direction: row;
            width: 100%;
          }

          div.location-details,
          img.location-picture {
            width: 50%;
            height: 45em;
          }

          div.location-details {
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          div.location-details div.details-container {
            width: 75%;
          }

          label.title {
            font-family: ${theme.fontFamilies.openSansBold};
            font-size: 1em;
            line-height: 1.375em;
          }

          h3 {
            font-size: 3.5em;
            line-height: 1.2em;
          }

          p {
            font-family: ${theme.fontFamilies.openSans};
          }
        }
      `}</style>
      <style global jsx>{`
        div.location-details p {
          font-family: ${theme.fontFamilies.montserrat};
          text-align: center;
        }

        .container .location-details .cta-container {
          height: 3.375em;
          font-family: ${theme.fontFamilies.openSansBold};
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.location-details p {
            font-family: ${theme.fontFamilies.openSans};
            text-align: justify;
          }
        }
      `}</style>
    </>
  );
};

Location.propTypes = propTypes;
Location.defaultProps = defaultProps;

export default Location;
