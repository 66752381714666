// Node modules
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

// Components
import Carousel from '@/components/global-components/carousel/Carousel';
import Location from '@/components/global-components/locations/Location';
import Container from '@/components/global-components/Container';

// Themes
import theme from '@/themes/index';

const defaultProps = {
  isDesktop: false,
};

const propTypes = {
  isDesktop: PropTypes.bool.isRequired,
};

const OurLocations = ({
  isMobile,
  isDesktop,
  content: {
    title,
    locations, 
  },
}) => {
  const getLocationsForMobileViewPort = () =>
    locations.map(({
      location,
    }) => (
      <div key={nanoid()} className='locations'>
        <Container >
          <Location {...location} title={title} customAttr={{
            'data-fslightbox': true,
          }} />
        </Container>
      </div>
    ));

  const getLocationsForTabletViewPort = () => (
    <>
      <Carousel showButtons={isDesktop} showPaging={true}>
        {getLocationsForMobileViewPort()}
      </Carousel>
    </>
  );

  return (
    <>
      <div className='container'>
        <h3>{title}</h3>
        <p>Divide your studies between 2 or all 3 of our sites!</p>
        <div className='locations-container'>
          {isMobile
            ? getLocationsForMobileViewPort()
            : getLocationsForTabletViewPort()}
        </div>
      </div>
      <style jsx>{`
        div.container {
          width: 100%;
          text-align: center;
        }

        div.locations-container {
          display: flex;
          flex-direction: column;
        }

        h3 {
          font-family: ${theme.fontFamilies.montserratMedium};
          padding-bottom: 1.5rem;
          margin-block-start: 0;
          margin-block-end: 0;
          margin-inline-start: 0;
          margin-inline-end: 0;
        }

        p {
          color: ${theme.fontColors.primary};
          font-family: ${theme.fontFamilies.montserrat};
          line-height: 2.25rem;
          font-size: 1.5rem;
          text-align: center;
          margin-top: 0;
        }

        @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
          h3 {
            padding-bottom: 2rem;
          }
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          h3 {
            padding-bottom: 0.813rem;;
          }

          div.locations-container {
            flex-direction: row;
          }
        }
      `}</style>
      <style global jsx>{`
        div.locations {
          margin-bottom: 5rem;
        }

        @media only screen and (min-width: ${theme.breakpoints.tabletMin}) {
          div.locations-container .slider-control-centerleft,
          div.locations-container .slider-control-centerright {
            top: 25% !important;
          }

          div.locations-container .slider-control-bottomcenter {
            bottom: -5rem !important;
          }
        }

        @media only screen and (min-width: ${theme.breakpoints.desktopMin}) {
          div.locations-container .slider {
          }

          div.locations-container .slider .slider-frame {
            margin-right: 1.2px !important;
          }

          div.locations-container .slider-control-centerleft,
          div.locations-container .slider-control-centerright {
            top: 50% !important;
          }

          div.locations-container .slider-control-bottomcenter {
            bottom: 0% !important;
            left: 75% !important;
          }
        }
      `}</style>
    </>
  );
};

OurLocations.propTypes = propTypes;
OurLocations.defaultProps = defaultProps;

export default OurLocations;
